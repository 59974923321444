import {
  fetchMarginReport,
  fetchOrderMarginReport,
  updateOrderMarginReport,
  getMarginReportPDF,
  getOrderMarginReportPDF,
} from 'api/marginReport.api';

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { toast } from 'react-toastify';
import { reduceMarginReportValues } from 'utils/data/reduceMarginReportValues'
import { createDuck } from './utils/createDuck';
import { downloadFile, printDocument } from './utils/parseFiles';

export const options = {
  name: 'marginReport',
  initialState: {
    marginReport: [],
    orderMarginReport: {},
    errorMessage: '',
    isLoading: false,
    filter: {
      client: null,
      object: null,
      employee: null,
      date: null,
    },
  },
  actions: {
    fetchMarginReportRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    fetchMarginReportSuccess: ({ data }) => state => ({
      ...state,
      isLoading: false,
      marginReport: data.reduce((prReport, report) => {
        const totalResults = prReport.totalResults;
        const incomePlan = Number((Number(totalResults[0].plan) + report.orders.reduce((prValue, article) => prValue + Number(article.plan.income), 0)).toFixed(2));
        const incomeFact = Number((Number(totalResults[0].fact) + report.orders.reduce((prValue, article) => prValue + Number(article.fact.income), 0)).toFixed(2));
        const marginalIncomePlan = Number((Number(totalResults[4].plan) + report.orders.reduce((prValue, article) => prValue + Number(article.plan.marginalIncome), 0)).toFixed(2));
        const marginalIncomeFact = Number((Number(totalResults[4].fact) + report.orders.reduce((prValue, article) => prValue + Number(article.fact.marginalIncome), 0)).toFixed(2));
        const grossProfitPlan = Number((Number(totalResults[2].plan) + report.orders.reduce((prValue, article) => prValue + Number(article.plan.grossProfit), 0)).toFixed(2));
        const grossProfitFact = Number((Number(totalResults[2].fact) + report.orders.reduce((prValue, article) => prValue + Number(article.fact.grossProfit), 0)).toFixed(2));

        return {
          totalResults: [
            {
              ...totalResults[0],
              plan: incomePlan,
              fact: incomeFact,
            },
            {
              ...totalResults[1],
              plan: Number((Number(totalResults[1].plan) + report.orders.reduce((prValue, article) => prValue + Number(article.plan.strExpenses), 0)).toFixed(2)),
              fact: Number((Number(totalResults[1].fact) + report.orders.reduce((prValue, article) => prValue + Number(article.fact.strExpenses), 0)).toFixed(2)),
            },
            {
              ...totalResults[2],
              plan: grossProfitPlan,
              fact: grossProfitFact,
            },
            {
              ...totalResults[3],
              plan: incomePlan === 0 ? 0 : (grossProfitPlan / incomePlan * 100),
              fact: incomeFact === 0 ? 0 : (grossProfitPlan / incomeFact * 100),
            },
            {
              ...totalResults[4],
              plan: Number((Number(totalResults[3].plan) + report.orders.reduce((prValue, article) => prValue + Number(article.plan.indirectCosts), 0)).toFixed(2)),
              fact: Number((Number(totalResults[3].fact) + report.orders.reduce((prValue, article) => prValue + Number(article.fact.indirectCosts), 0)).toFixed(2)),
            },
            {
              ...totalResults[5],
              plan: marginalIncomePlan,
              fact: marginalIncomeFact,
            },
            {
              ...totalResults[6],
              plan: incomePlan === 0 ? 0 : (marginalIncomePlan / incomePlan * 100),
              fact: incomeFact === 0 ? 0 : (marginalIncomeFact / incomeFact * 100),
            },
          ],
          data: [
            ...prReport.data,
            {
              clientId: report.clientId,
              clientName: report.clientName || report.clientLegalName,
              orders: report.orders.reduce((prArticle, article) => (
                [
                  ...prArticle,
                  {
                    orderId: article.orderId,
                    objectName: article.object ? article.object.name : 'null',
                    values: reduceMarginReportValues(article)
                  }
                ]
              ), [])
            }
          ]
        }
      }, {
        totalResults: [
          {
            id: 1, name: 'Выручка', bigArticle: true, plan: 0, fact: 0,
            calcResult: function () { return Number(this.fact) - Number(this.plan) },
          },
          {
            id: 2, name: 'Прямые затраты', bigArticle: true, plan: 0, fact: 0,
            calcResult: function () {
              return Number(this.plan) - Number(this.fact)
            },
          },
          {
            id: 3, name: 'Валовая прибыль', bigArticle: true, plan: 0, fact: 0,
            calcResult: function () {
              return Number(this.fact) - Number(this.plan)
            },
          },
          { id: 4, name: 'Рентабельность 1, %', bigArticle: true, suffix: ' %', plan: 0, fact: 0, },
          {
            id: 5, name: 'Косвенные затраты', bigArticle: true, plan: 0, fact: 0,
            calcResult: function () {
              return Number(this.plan) - Number(this.fact)
            },
          },
          {
            id: 6, name: 'Маржинальный доход', bigArticle: true, plan: 0, fact: 0,
            calcResult: function () {
              return Number(this.fact) - Number(this.plan)
            },
          },
          { id: 7, name: 'Рентабельность общая, %', bigArticle: true, suffix: ' %', plan: 0, fact: 0, },
        ],
        data: []
      }),
    }),
    fetchMarginReportFailure: ({ message }) => state => ({
      ...state,
      isLoading: false,
      errorMessage: message,
    }),

    fetchOrderMarginReportRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    fetchOrderMarginReportSuccess: ({ data }) => state => ({
      ...state,
      isLoading: false,
      orderMarginReport: {
        marginIncomeId: data.marginalIncomeId,
        clientName: data.client.name,
        objectName: data.object ? data.object.name : 'null',
        values: reduceMarginReportValues(data)
      },
    }),
    fetchOrderMarginReportFailure: ({ message }) => state => ({
      ...state,
      isLoading: false,
      errorMessage: message,
    }),

    updateOrderMarginReportRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    updateOrderMarginReportSuccess: () => state => ({
      ...state,
      isLoading: false,
    }),
    updateOrderMarginReportFailure: ({ message }) => state => ({
      ...state,
      isLoading: false,
      errorMessage: message,
    }),
    getMarginReportPDFRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    getMarginReportPDFSuccess: () => state => ({
      ...state,
      isLoading: false,
    }),
    getMarginReportPDFFailure: ({ message }) => state => ({
      ...state,
      isLoading: false,
      errorMessage: message,
    }),
    getOrderMarginReportPDFRequest: () => state => ({
      ...state,
      isLoading: true,
    }),
    getOrderMarginReportPDFSuccess: () => state => ({
      ...state,
      isLoading: false,
    }),
    getOrderMarginReportPDFFailure: ({ message }) => state => ({
      ...state,
      isLoading: false,
      errorMessage: message,
    }),

    setFilter: filter => state => ({ ...state, filter }),
  },
  effects: {
    fetchMarginReport: params => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.fetchMarginReportRequest());
      try {
        const response = await fetchMarginReport(params);
        dispatch(duckActions.fetchMarginReportSuccess(response));
      } catch (error) {
        dispatch(duckActions.fetchMarginReportFailure(error));
      }
    },
    fetchOrderMarginReport: id => async (dispatch, getState, duckActions) => {
      dispatch(duckActions.fetchOrderMarginReportRequest());
      try {
        const response = await fetchOrderMarginReport(id);
        dispatch(duckActions.fetchOrderMarginReportSuccess(response));
      } catch (error) {
        dispatch(duckActions.fetchOrderMarginReportFailure(error));
      }
    },
    updateOrderMarginReport: ({ id, data }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.updateOrderMarginReportRequest());
      try {
        const res = await updateOrderMarginReport(id, data);
        dispatch(duckActions.updateOrderMarginReportSuccess());
        dispatch(duckEffects.fetchOrderMarginReport(id));
        toast.success('Данные отчета изменены');
        return res.data;
      } catch (error) {
        dispatch(duckActions.updateOrderMarginReportFailure(error));
      }
    },
    parsePDFMakeObj: ({ response, isDownload, name = 'document' }) => {
      // fix 'Roboto-Medium.ttf' not found in virtual file system pdfMake error
      pdfMake.vfs = pdfFonts.pdfMake.vfs;

      if (isDownload) {
        pdfMake.createPdf(response).download(name);
      } else {
        const pdfDocGenerator = pdfMake.createPdf(response);
        pdfDocGenerator.getBlob(blob => {
          const objUrl = window.URL.createObjectURL(
            new Blob([blob], { type: 'application/pdf' })
          );
          printDocument(objUrl);
        });
      }
    },
    getMarginReportPDF: ({ params, isDownload = false, name = '' }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.getMarginReportPDFRequest());
      try {
        const response = await getMarginReportPDF(params);
        dispatch(duckEffects.parsePDFMakeObj({ response, isDownload, name }));

        dispatch(duckActions.getMarginReportPDFSuccess());
      } catch (error) {
        dispatch(duckActions.getMarginReportPDFFailure(error));
      }
    },
    parsePDFDocument: ({ response, isDownload, name = 'document' }) => {
      const objUrl = window.URL.createObjectURL(
        new Blob([response], { type: 'application/pdf' })
      );

      if (isDownload) downloadFile(objUrl, name);
      else printDocument(objUrl);
    },
    getOrderMarginReportPDF: ({ id, isDownload = false, name = '' }) => async (dispatch, getState, duckActions, duckEffects) => {
      dispatch(duckActions.getOrderMarginReportPDFRequest());
      try {
        const response = await getOrderMarginReportPDF(id);
        dispatch(duckEffects.parsePDFDocument({ response, isDownload, name }));

        dispatch(duckActions.getOrderMarginReportPDFSuccess());
      } catch (error) {
        dispatch(duckActions.getOrderMarginReportPDFFailure(error));
      }
    }
  },
  selectors: {
    getMarginReport: (getState, createSelector) => createSelector([getState], s => s.marginReport),
    getOrderMarginReport: (getState, createSelector) => createSelector([getState], s => s.orderMarginReport),
    getErrorMessage: (getState, createSelector) => createSelector([getState], s => s.errorMessage),
    getFilter: (getState, createSelector) => createSelector([getState], s => s.filter),
    isLoading: (getState, createSelector) => createSelector([getState], s => s.isLoading),
  },
};

export const { actions, selectors, effects, reducer } = createDuck(options);
